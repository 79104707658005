import { Component, inject } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { InvoicePaymentPageComponent } from '../../../shared/invoice-modal/invoice-payment-page/invoice-payment-page.component';
import { ObjectLiteral } from '../../../core/models/ObjectLiteral';

@Component({
  selector: 'app-available-payment-gateways',
  standalone: true,
  imports: [InvoicePaymentPageComponent, CommonModule, SvgIconComponent],
  templateUrl: './available-payment-gateways.component.html',
  styleUrl: './available-payment-gateways.component.css',
  host: {
    class: 'bg-white',
  },
})
export class AvailablePaymentGatewaysComponent {
  locationService = inject(Location);
  tpui: string;

  goBack() {
    this.locationService.back();
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    const state: ObjectLiteral = this.locationService.getState();
    console.log(state); // { id: 123, name: 'John' }
    this.tpui = state?.tpui || '';
  }
}
